import React from 'react'

import './CareerSection.css'

const CareerSection = () => {
  return (
    <>

        <div className='career-section'>
        <div className='career-section-content'>
            <iframe src="https://www.paycomonline.net/v4/ats/web.php/jobs?
clientkey=31A83E63E9D18C0B219298A8B824A606&fromClientSide=true" title='paycom'></iframe>
            </div>
        </div>


    </>
  )
}

export default CareerSection
